import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HorseGirl from "/content/assets/resources/resource-girlhorse.jpg"
// import { navigate } from 'gatsby'
import { Helmet } from "react-helmet"

const Grants = ({ data }) => {
  // navigate('/scholarships');
  return (
    <Layout>
      <Helmet>
      <title>Grants</title>
    </Helmet>
    <section class="hero is-halfheight hero-banner" id="grants-hero">
          <div class="hero-body">
          </div>
    </section>      
    <main>
        <h1>Grants</h1>
        <div class="article-image-right">
          <img src={HorseGirl} alt="Girl and Horse" />
        </div>
        <p>The Kentucky Equine Education Project (KEEP) Foundation is proud to offer the new Seattle Slew grant program. The KEEP Foundation is committed to helping support educational programs, projects and other endeavors that help advance the future of the equine industry in Kentucky. </p>
        <p>KEEP Foundation's Seattle Slew grants are available to Kentucky non-profits, equine educational programs, events and initiatives. The Seattle Slew grant program is part of the Foundation's efforts to support education and promote the importance of the equine industry to Kentucky's economy and culture.</p>
        <p>
          The grant program is open to non-profit organizations, schools, and other groups that are involved in equine-related educational programs and projects. Examples of eligible programs and projects include, but are not limited to:
          <ul>
            <li>Equine-related STEM education initiatives</li>
            <li>Equine-assisted therapy programs</li>
            <li>Equine-related vocational training programs</li>
            <li>Equine-related art or cultural initiatives</li>
            <li>Equine-related history and heritage projects</li>
          </ul>
        </p>
        <p>Grants of up to $5,000 are available, and new submissions will be reviewed quarterly. For more information or to apply for a grant, please visit <a href="https://docs.google.com/forms/d/e/1FAIpQLSdf8cbCqrUZplXbbB5q8EWUHCJYzsPauktfV1OjJ3kZxHQm0A/viewform?usp=sf_link" target="_blank" rel="noreferrer">HERE</a>.</p>
    </main>
    </Layout>
  )
}

export default Grants

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`